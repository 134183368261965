<template>
  <div>
    <div class="tabs">
      <span @click="routerIndex()">技术支持与服务 > </span>
      <span >常见问题解答 </span>
    </div>
    <search-box :info="searchInfo" @childEvent="handleChildEvent"></search-box>
    <div class="content">
      <el-card>
        <el-menu default-active="1-0" class="el-menu-vertical-demo">
          <el-submenu index="1" @click.native="groupIndex = 0">
            <template slot="title">
              <span>产品问题</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="(item, i) in questionList[groupIndex]"
                :key="i"
                @click="itemIndex = i"
                :index="'1-' + i"
              >
                {{ item.name }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2" @click.native="groupIndex = 1">
            <template slot="title">
              <span>技术问题</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="(item, i) in questionList[1]"
                :key="i"
                @click="itemIndex = i"
                :index="'2-' + i"
              >
                {{ item.name }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3" @click.native="groupIndex = 2">
            <template slot="title">
              <span>售后问题</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="(item, i) in questionList[2]"
                :key="i"
                @click="itemIndex = i"
                :index="'3-' + i"
              >
                {{ item.name }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-card>
      <el-card>
        <div v-for="(item, index) in questionList[groupIndex]" :key="index">
          <div v-if="itemIndex == index">
            <div class="question">{{ item.name }}</div>
            <div class="answer">{{ item.content }}</div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="consult">
      <div class="title">有问题未得到解决?</div>
      <div class="detail-text">
        通过您喜欢的方式与我们联系，获取协助或反馈你的建议。
      </div>
      <div class="consult-card">
        <div class="card" v-for="(item, i) in consultList" :key="i" @click="openKeFu(i)">
          <img :src="item.img" alt="" />
          <div class="way">{{ item.way }}</div>
          <div class="contact">
            <img v-if="i === 0" src="../../assets/support/kf.png" alt="" />
            <span>{{ item.contact }}</span>
          </div>
          <div class="time">{{ item.time }}</div>
        </div>
      </div>
    </div>
    <!-- 在线咨询客服页 -->
    <div v-show="isShow" class="online">
      <div class="onlineContent">
        <div class="online-header">
          <div class="online-header-left">
            <img src="../../assets/support/logo.png" alt="" />
            <span>在线客服</span>
          </div>
          <div class="online-header-right">
            <img @click="closeKeFu()"  src="../../assets/support/close.png" alt="" />
          </div>
        </div>
        <!-- 中间部分 -->
        <div class="onlineBox">
          <div class="onlineBox-header">
            <div class="onlineBox-header-left">
              <img src="../../assets/support/bell.png" alt="" />
              <span>回答仅供参考，选购及使用前请与技术人员确认。</span>
            </div>
            <div class="onlineBox-header-right">
              <img src="../../assets/support/hot.png" alt="" />
              <span>热门问题</span>
            </div>
          </div>
          <div class="onlineBox-main">
            <div class="onlineBox-left">
              <div class="chat-container">
                <div class="chat-messages">
                  <div v-for="(message, index) in messages" :key="index" style="white-space: pre-wrap;">
                    <div class="askTime" v-show="message.isUser">
                      {{ message.time }}
                    </div>
                    <div
                     v-show="message.isUser"
                      class="message"
                      :class="{'user-message': message.isUser}">
                      {{ message.text }}
                    </div>
                    <div v-show="!message.isUser"  class="kefu" :style="{'margin-top':index==0?'':'1.2rem'}">
                        <img class="kefuAvatar" src="../../assets/support/avatar.png" alt="">
                        <span class="kefuTime">在线客服   {{ message.time }}</span>
                        <div class="replay">{{ message.replay }}</div>
                    </div>
                  </div>
                </div>
                <div class="chat-input">
                  <textarea
                    v-model="newMessage"
                    @keyup.enter="sendMessage"
                    placeholder="很高兴为您服务，请描述您的问题"

                  />
                  <button @click="sendMessage">Send</button>
                </div>
              </div>
            </div>
            <div class="onlineBox-right">
              <div class="hotQuestion">
                <div class="questions" v-for="(item,i) in hotQuestion" :key="i">
               {{ i+1 }}.{{ item.question }}</div>
              </div>
              <div class="block">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage1"
                  :page-size="10"
                  layout="total, prev, pager, next"
                  :total="20"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBox from "./SearchBox.vue";
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
  components: { SearchBox },
  data() {
    return {
      // 时间
      time: "",
      // 客服页面是否显示
      isShow: false,
      messages: [],
      newMessage: "",
      //  热门问题
      hotQuestion:[
        {question:'产品详情'},
      ],
      //   当前页
      currentPage1: 1,
      // 子组件传递过来的值
      message: "",
      searchInfo: {
        // title: "您需要什么帮助？",
        title:'常见问题解答',
        img: require("../../assets/support/cjwttt@2x.png"),
      },
      groupIndex: 0,
      itemIndex: 0,
      questionList: [
        [
          {
            name: "问题名称1",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
          {
            name: "问题名称2",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
          {
            name: "问题名称3",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
          {
            name: "问题名称4",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
        ],
        [
          {
            name: "问题名称1",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
          {
            name: "问题名称2",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
          {
            name: "问题名称3",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
          {
            name: "问题名称4",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
        ],
        [
          {
            name: "问题名称1",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
          {
            name: "问题名称2",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
          {
            name: "问题名称3",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
          {
            name: "问题名称4",
            content: "解答内容解答内容解答内容解答内容解答内容解答内容",
          },
        ],
      ],
      consultList: [
        {
          way: "在线咨询",
          contact: "在线咨询客服",
          time: "周一至周日09:00-23:00",
          img: require("../../assets/support/zxzx@2x.png"),
        },
        {
          way: "电话咨询",
          contact: "18758021092",
          time: "周一至周日09:00-23:00",
          img: require("../../assets/support/dhzx@2x.png"),
        },
        {
          way: "邮箱咨询",
          contact: "36299527@qq.com",
          time: "一个工作日内回复",
          img: require("../../assets/support/yxzx@2x.png"),
        },
      ],
    };
  },
  created(){
    this.Date();
    this.messages.push({
            replay: "您好，我是智能客服小景，很高兴为您服务！",
            isUser: false,
            time: this.time,
          });
  },
  methods: {
    // 返回技术支持与服务
    routerIndex(){
      this.$router.push("/support");
      this.$store.commit("changeIndex", 2);
    },
    // 传值给子组件
    handleChildEvent(value) {
      this.message = value;
      console.log(this.message);
    },
    // 打开客服页
    openKeFu(i){
      if(i==0){
        this.isShow = true;
      }

    },
    // 关闭客服页
    closeKeFu(){
      this.isShow = false;
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    sendMessage() {
      this.Date();
      if (this.newMessage.trim() !== "") {
        this.messages.push({
          text: this.newMessage,
          isUser: true,
          time: this.time,
        });
        this.newMessage = "";
        // 在实际应用中，你可以在这里发送消息给客服
        setTimeout(() => {
          this.messages.push({
            replay: "稍等片刻,答案正在为您生成中~",
            isUser: false,
            time: this.time,
          });
        }, 1000);
      }
    },
    sendQuickReply(reply) {
      this.messages.push({ text: reply, isUser: false });
      // 在实际应用中，你可以在这里发送快捷回复给客服
    },
    // 获取时间戳
    Date() {
      const nowDate = new Date();
      const date = {
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      };

      const newminutes = date.minutes > 10 ? date.minutes : "0" + date.minutes;
      const newseconds = date.seconds > 10 ? date.seconds : "0" + date.seconds;

      this.time = date.hours + ":" + newminutes + ":" + newseconds;
    },
  },
};
</script>

<style scoped lang="scss">
/* // 导航条 */
.tabs {
  width: 100%;
  height: 38px;
  background: #ffffff;
  border-top: 2px solid rgba(219, 219, 219, 0.5);
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  line-height: 38px;
  font-size: 0.2rem;
  // margin-top: 1.1rem;
  box-sizing: border-box;
  span:nth-child(1) {
    margin-left: 24px;
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(2) {
    color: #565656;
    cursor: pointer;
  }
}
// 客服页
.online {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #000000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box;
  .onlineContent {
    width: 12rem;
    min-width: 700px;
    height: 8rem;
    // min-height: 600px;
    background: #ffffff;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    opacity: 1;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    .online-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: #127bf2;
      border-radius: 20px 20px 0px 0px;
      padding: 0.1rem;
      box-sizing: border-box;
      height: 0.8rem;
      box-sizing: border-box;
      .online-header-left {
        span {
          vertical-align: middle;
          font-size: 0.25rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 0.1rem;
          cursor: pointer;
        }
        img {
          vertical-align: middle;
          width: 0.6rem;
        }
      }
      .online-header-right {
        img {
          width: 0.3rem;
        }
      }
    }
    .onlineBox {
      .onlineBox-header {
        display: flex;
        justify-content: space-between;
        padding: 0.1rem;
        box-sizing: border-box;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
        border-radius: 0px 0px 0px 0px;
        height: 0.6rem;
        .onlineBox-header-left {
          width: 8rem;
          img {
            vertical-align: middle;
            width: 0.25rem;
            margin-left: 0.2rem;
          }
          span {
            vertical-align: middle;
            font-size: 0.22rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-left: 0.1rem;
          }
        }
        .onlineBox-header-right {
          width: 4rem;
          img {
            vertical-align: middle;
            width: 0.27rem;
          }
          span {
            vertical-align: middle;
            font-size: 0.3rem;
            font-family: Source Han Sans-Medium, Source Han Sans;
            font-weight: 500;
            color: #333333;
            margin-left: 0.1rem;
          }
        }
      }
      .onlineBox-main{
        display: flex;
        // height: 8rem;
      }
      .onlineBox-left {
        background: #f9f9f9;
        width: 8rem;
        height: 6.6rem;
        .chat-container {
          //   max-width: 400px;
          width: 8rem;
          height: 6.6rem;
          overflow: hidden;
        }

        .chat-messages {
          padding: 10px;
          height: 5rem;
          overflow-y: auto;
          box-sizing: border-box;
          overflow-x: hidden;
        }

        .message {
          padding:  0.1rem;
          // height: 0.6rem;
          // line-height: 0.6rem;
          border-radius: 5px;
          max-width: 70%;
          box-sizing: border-box;
          white-space: pre-wrap;
          font-size: 0.25rem;
        }
        .askTime {
          float: right;
          clear: both;
          font-size: 0.25rem;
          color: #333333;
        }
        .user-message {
          background-color: #007bff;
          color: #fff;
          float: right;
          clear: both;
          white-space: wrap;
        }

        .agent-message {
          background-color: #eee;
          float: left;
          clear: both;
        }
        // 客服回复
        .kefu{
          color:#333333;
          img{
            vertical-align: middle;
            width: 0.4rem;
          }
          span{
            vertical-align: middle;
            margin: 0 0.1rem;
            font-size: 0.25rem;
          }
          .replay{
            font-size: 0.25rem;
          }
        }
        .chat-input {
          display: flex;
          width: 8rem;
          height: 1.6rem;
          box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.06);
          border-radius: 0px 0px 0px 20px;
          box-sizing: border-box;
          position: relative;
          textarea {
            padding: 10px;
            border: none;
            width: 8rem;
            height: 1.6rem;
            vertical-align: top;
            box-sizing: border-box;
          }
          textarea:focus,  textarea:active {
            border: none;
            outline: none;
          }
          button {
            position: absolute;
            top: 0.1rem;
            right: 0.1rem;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 5px;
            padding: 0 0.2rem;
            font-size: 0.2rem;
            cursor: pointer;
            height:0.5rem;
            line-height: 0.5rem;
            box-sizing: border-box;
          }
        }
      }
      .onlineBox-right{
        height: 6.6rem;
        .hotQuestion{
          height: 5rem;
          color:#333333;
          font-size: 0.25rem;
          line-height: 0.4rem;
          cursor: pointer;
        }
      }
    }
  }
}
.content {
  display: flex;
  margin: 0.5rem 1.6rem;
}
.el-card {
  width: 4.48rem;
  margin-right: 0.18rem;
}
.el-card:nth-child(2) {
  width: 11.14rem;
}
.el-menu {
  border: 0px;
}

.question {
  font-size: 0.24rem;
  font-weight: 600;
  color: #272727;
}
.answer {
  font-size: 0.24rem;
  color: #909090;
  margin-top: 0.2rem;
}

.consult {
  margin: 0.4rem 1.6rem 0.8rem;
}
.consult .title {
  font-size: 0.36rem;
  font-weight: 600;
  color: #272727;
  margin-block: 0.15rem;
}
.detail-text {
  font-size: 0.2rem;
  color: #9b9b9b;
}
.consult-card {
  margin-top: 0.55rem;
  display: flex;
  justify-content: space-between;
}

.card {
  position: relative;
}
.card > img {
  width: 4.6rem;
  height: 2.45rem;
}
.card > div {
  position: absolute;
}
.way {
  left: 0.42rem;
  top: 0.38rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #272727;
}
.contact {
  left: 0.42rem;
  top: 0.97rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #272727;
}
.card:nth-of-type(1) .contact {
  width: 2rem;
  height: 0.52rem;
  background: #ffffff;
  border-radius: 0.26rem;
  border: 1px solid #127bf2;
  font-size: 0.2rem;
  color: #127bf2;
  /* margin: auto; */
  text-align: center;
  line-height: 0.52rem;
}
.contact img {
  width: 13px;
  height: 13px;
  margin-right: 5px;
}
.time {
  left: 0.42rem;
  top: 1.87rem;
  font-size: 0.18rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #9b9b9b;
}
</style>
